import Sortable from "sortablejs";

$(document).on("turbo:load", function () {
  document.querySelectorAll(".sortable").forEach((sortable) => {
    const $sortable = $(sortable);

    new Sortable(sortable, {
      group: $sortable.is(".nested") ? "nested" : undefined,
      handle: ".draggable",
      direction: $sortable.is(".vertical") ? "vertical" : "horizontal",
      animation: 150,
      fallbackOnBody: true,
      swapThreshold: 0.65,
      isValidTarget: function (item, container) {
        if (item.is(".parent") && !$(container.el[0]).is(".parent")) {
          // prevent dragging a parent into a parent in app/views/survey/display_profiles/sort_items.html.erb
          return false;
        }
        return true;
      },
    });
  });
});
