import "@hotwired/turbo-rails";
import "@popperjs/core";
import * as bootstrap from "bootstrap";
import "iframe-resizer";
import iframeResize from "iframe-resizer/js/iframeResizer";
import flatpickr from "flatpickr";

import "@/tom-select.js";
import "@/jquery_elastic_source.js";
import "@/tinymce-setup.js";
import "@/sortable.js";
import "@/survey_periodic_handout.js";
import "@/home.js";

window.bootstrap = bootstrap;

import { checkDuplicateTab } from "@/duplicate-tab.js";

import { Danish } from "flatpickr/dist/l10n/da.js";

//Suboptimal, but works as a workaround until we get stimulus fully implemented
document.addEventListener("turbo:submit-end", (event) => {
  if (!event.detail.success) {
    setTimeout(() => {
      document.dispatchEvent(new Event("turbo:load"));
    }, 200);
  }
});

$(document).on("turbo:load", () => {
  flatpickr(".flatpickr", {
    locale: Danish,
    dateFormat: "d-m-Y",
  });

  [...document.querySelectorAll('[data-bs-toggle="popover"]')].forEach(
    (el) => new bootstrap.Popover(el)
  );

  // Somehow BS tooltips are not working unless we wait a bit
  setTimeout(() => {
    [...document.querySelectorAll('[data-bs-toggle="tooltip"]')].forEach(
      (el) => new bootstrap.Tooltip(el)
    );
  }, 200);

  iframeResize({ log: false }, "#iframe");
});


checkDuplicateTab(() => {
  document.open();
  document.write(`
    <div style="padding: 1rem; text-align: center">
      <h1>Kun muligt at arbejde i en fane</h1>
      <h3>Du har en anden fane åben med dette system.</h3>
    </div>
  `);
  document.close();
});