import typeahead from "typeahead-standalone";

$(document).on("turbo:load", function () {
  if (!document.querySelector("input[name='patient_cpr']")) {
    return;
  }

  $("input[name='patient_cpr']").elastic();

  typeahead({
    input: document.querySelector("input[name='patient_cpr']"),
    limit: 10,
    source: {
      remote: {
        url: "/patients/search/?q=%QUERY",
        wildcard: "%QUERY",
      },
      identifier: "name",
    },
    templates: {
      suggestion: (item) => {
        return `<li><a href="#" class="dropdown-item tt-item">${item.name}</a></li>`;
      },
    },
    display: (item) => {
      return item.name;
    },
    classNames: {
      list: "dropdown-menu",
    },
  });

  document
    .querySelector("input[name='patient_cpr']")
    .addEventListener("input", function (event) {
      // typeahead will trigger the input event with isTrusted=false, for selecting an item.
      // otherwise it is a user input.
      // @see https://typeahead.digitalfortress.tech/#references?id=faq
      if (event.isTrusted) {
        return;
      }

      $(event.target).closest("form").submit();
    });

  $("input[name='patient_cpr']")
    .closest("form")
    .on("submit", function (event) {
      const input = $(event.target).find("input[name='patient_cpr']");
      const query = input.val();

      input.val(query.replace(/^(test\s*)/i, ""));
    });
});
